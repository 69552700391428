import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../../styles/style";
import editIcon from "../../../images/editIcon.svg";
import filterIcon from "../../../images/filterIcon.svg";
import heightIcon from "../../../images/heightIcon.svg";
import weightIcon from "../../../images/weightIcon.svg";
import {
  SmallIcon,
  SmallText,
  SmallTextConstant,
  SmallTextSlim,
} from "../../shared/SharedStyles";
import { HeaderAndEditWrapper, HeaderText } from "../shared/SharedStyles";
import { useBabyContext } from "../../../context/BabyContext";
import { EditModal } from "../../shared/EditModal";
import heightandweightIcon from "../../../images/heightandweightIcon.svg";
import Button from "../../../helpers/components/Button";
import { formatTimestampToDate } from "../../../common/helpers";
import { addBabyMeasurement } from "../../../api";
import goBackIcon from "../../../images/goBackIcon.svg";
import {
  CustomTextInput,
  CustomDateInput,
} from "../../../helpers/components/CustomInputs";
import { AuthContext } from "../../../context/AuthContext";

export const HeightAndWeight = () => {
  const { hasActivePayment } = React.useContext(AuthContext);
  const { selectedBaby, refreshBabyContext } = useBabyContext();
  const [isModalOpen, setModalOpen] = useState(false);
  const [measurements, setMeasurements] = useState({
    height: "",
    weight: "",
    created_at: "",
  });
  const [loading, setLoading] = useState(false);
  const [sortOrder, setSortOrder] = useState("desc");

  const handleButtonClick = () => {
    setModalOpen(true);
  };
  const handleCloseModal = () => {
    setModalOpen(false);
    setMeasurements({ height: "", weight: "", created_at: "" });
  };

  const isNumeric = (value: any) => {
    return !isNaN(parseFloat(value)) && isFinite(value);
  };
  const toggleSortOrder = () => {
    setSortOrder(sortOrder === "desc" ? "asc" : "desc");
  };

  const sortedMeasurements = selectedBaby ? [...selectedBaby.measurements] : [];
  sortedMeasurements.sort((a, b) => {
    const dateA = new Date(a.created_at ?? a.timestamp).getTime();
    const dateB = new Date(b.created_at ?? b.timestamp).getTime();
    return sortOrder === "desc" ? dateB - dateA : dateA - dateB;
  });

  const isButtonClickable =
    measurements.height !== "" &&
    measurements.weight !== "" &&
    isNumeric(measurements.height) &&
    isNumeric(measurements.weight);

  const handleUpdate = async (e: any) => {
    setLoading(true);
    e.preventDefault();
    const payload = {
      height: measurements.height,
      weight: measurements.weight,
      created_at: measurements.created_at
        ? Math.floor(new Date(measurements.created_at).getTime() / 1000)
        : new Date(),
    };
    await addBabyMeasurement(selectedBaby.id, payload)
      .then(() => {
        refreshBabyContext();
        setMeasurements({ height: "", weight: "", created_at: "" });
      })
      .catch((e) => {
        console.error(e);
      });
    setLoading(false);
  };
  return (
    <React.Fragment>
      <HeightAndWeightContainer>
        <HeaderAndEditWrapper>
          <HeaderText>Height and Weight</HeaderText>
          <SmallIcon src={editIcon} onClick={handleButtonClick} />
        </HeaderAndEditWrapper>
        <HeightAndWeightWrapper>
          <MeasurementsWrapper>
            <SmallIcon src={heightIcon} />
            <SmallTextSlim color={colors.darkBrown}>Height</SmallTextSlim>
            <MeasurementValue>
              {selectedBaby && selectedBaby.measurements[0].height
                ? selectedBaby.measurements[0].height
                : 20}{" "}
              cm
            </MeasurementValue>
          </MeasurementsWrapper>
          <MeasurementsWrapper>
            <SmallIcon src={weightIcon} />
            <SmallTextSlim color={colors.darkBrown}>Weight</SmallTextSlim>
            <MeasurementValue>
              {selectedBaby && selectedBaby.measurements[0].weight
                ? selectedBaby.measurements[0].weight
                : 7}{" "}
              gr
            </MeasurementValue>
          </MeasurementsWrapper>
        </HeightAndWeightWrapper>
      </HeightAndWeightContainer>

      <EditModal isOpen={isModalOpen} onClose={handleCloseModal}>
        <HeaderContainer>
          <HeaderIcon src={goBackIcon} onClick={handleCloseModal} />
          <SmallText color={colors.darkBrown} style={{ margin: "auto" }}>
            Height and Weight
          </SmallText>
        </HeaderContainer>
        <HeightAndWeightIcon src={heightandweightIcon}></HeightAndWeightIcon>
        <HeightAndWeightForm>
          <CustomTextInput
            label="Height"
            value={measurements.height}
            onChange={(e) =>
              setMeasurements({ ...measurements, height: e.target.value })
            }
            name="height"
            placeholder="Enter Height"
            icon="cm"
            $bigLabel={true}
          />
          <CustomTextInput
            label="Weight"
            value={measurements.weight}
            onChange={(e) =>
              setMeasurements({ ...measurements, weight: e.target.value })
            }
            name="weight"
            placeholder="Enter Weight"
            icon="gr"
            $bigLabel={true}
          />
          <CustomDateInput
            label="Date of Measurement"
            placeholder="Select Date"
            value={measurements.created_at}
            onChange={(e) =>
              setMeasurements({ ...measurements, created_at: e.target.value })
            }
            $bigLabel={true}
          />
          <Button
            $button_type="colored"
            onClick={handleUpdate}
            disabled={!isButtonClickable || loading || !hasActivePayment}
            loading={loading}
          >
            Update
          </Button>
        </HeightAndWeightForm>

        <HistoryWrapper>
          <ContainerHeader>
            <HeaderText>History</HeaderText>
            <SmallIcon src={filterIcon} onClick={toggleSortOrder} />
          </ContainerHeader>
          {sortedMeasurements.map((measurement: any, index: any) => (
            <HistoryRow key={index}>
              <SmallTextSlim color={colors.deepBrown}>
                {formatTimestampToDate(
                  measurement.created_at ?? measurement.timestamp
                )}
              </SmallTextSlim>
              <HistoryStat>{`${measurement.height} cm | ${measurement.weight} gr`}</HistoryStat>
            </HistoryRow>
          ))}
        </HistoryWrapper>
      </EditModal>
    </React.Fragment>
  );
};

const HeightAndWeightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 16px;
  gap: 16px;
`;

const HeightAndWeightWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
`;
const MeasurementsWrapper = styled.div`
  border-radius: 20px;
  border: 1px solid ${colors.borderWhite};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 48px;
  padding: 0 12px;
`;

const MeasurementValue = styled.p`
  color: ${colors.softBrown};
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.27px;
`;

const HeightAndWeightIcon = styled.img`
  width: 90px;
  height: 90px;
  align-self: center;
  justify-self: center;
`;

const HeightAndWeightForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 0 12px;
`;

const HistoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  margin-bottom: 32px;
  min-height: 100px;
  max-height: 150px;
  overflow-y: scroll;
`;

const HistoryRow = styled.div`
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid ${colors.borderWhite};
  padding-bottom: 8px;
  padding-top: 12px;
`;

const HistoryStat = styled.p`
  color: ${colors.softBrown};
  text-align: right;
  ${SmallTextConstant};
`;
const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 16px;
  width: 100%;
`;
const HeaderIcon = styled.img`
  width: auto;
  height: auto;
`;
const ContainerHeader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;

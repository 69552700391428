import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { getBabyById, getLatestAddedBaby } from "../api";
import { AuthContext } from "./AuthContext";

interface BabyContextState {
  selectedBabyId: string | null;
  updateSelectedBaby: (babyId: string | null) => {};
  selectedBaby: any;
  postAdded: boolean;
  setPostAdded: React.Dispatch<React.SetStateAction<boolean>>;
  refreshBabyContext: () => void;
  device: any;
  updateDevice: (device: any) => void;
  refreshGetDefaultBaby: () => void;
}

const BabyContext = createContext<BabyContextState | undefined>(undefined);

export const useBabyContext = () => {
  const context = useContext(BabyContext);
  if (!context) {
    throw new Error("useBabyContext must be used within a BabyProvider");
  }
  return context;
};

interface BabyProviderProps {
  children: ReactNode;
}

export const BabyProvider: React.FC<BabyProviderProps> = ({ children }) => {
  const [selectedBabyId, setSelectedBabyId] = useState<any>(
    localStorage.getItem("selected-baby")
  );
  const [selectedBaby, setSelectedBaby] = useState<any>();
  const [postAdded, setPostAdded] = useState<boolean>(false);
  const [device, setDevice] = useState<any>();
  const { hasActivePayment } = React.useContext(AuthContext);
  console.log(selectedBabyId);
  useEffect(() => {
    const accessToken = localStorage.getItem("little-feather-token");
    if (selectedBabyId && accessToken) {
      handleGetBaby(selectedBabyId);
    } else if (accessToken) {
      getDefaultLatestBabyId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedBabyId]);

  const getDefaultLatestBabyId = async () => {
    if (window.location.pathname === "/add-baby") {
      return;
    }
    console.log("getDefaultLatestBabyId");
    await getLatestAddedBaby().then(async ({ data }: any) => {
      if (data?.length === 0 || !data) {
        if (!hasActivePayment) {
          if (window.location.pathname === "/settings") {
            return;
          }
          window.location.href = "/settings";
          return;
        }
        // window.location.href = "/add-baby";
        return;
      }
      await updateSelectedBaby(data.id);
    });
  };

  const handleGetBaby = async (babyId: any) => {
    await getBabyById(babyId).then(({ data }) => {
      setSelectedBaby(data);
      // window.location.reload();
    });
    console.log("handleGetBaby");
  };

  const refreshBabyContext = () => {
    if (selectedBabyId) {
      handleGetBaby(selectedBabyId);
    }
  };

  const updateSelectedBaby = async (babyId: string | null) => {
    localStorage.removeItem("selected-baby");
    localStorage.setItem("selected-baby", babyId ?? "");

    setSelectedBabyId(babyId);
    // window.location.reload();

    await handleGetBaby(babyId);
  };

  const updateDevice = (device: any) => {
    setDevice(device);
  };
  const refreshGetDefaultBaby = async () => {
    const storedBabyId = localStorage.getItem("selected-baby");
    await handleGetBaby(storedBabyId);
  };
  const contextValue: BabyContextState = {
    selectedBabyId,
    updateSelectedBaby,
    selectedBaby,
    postAdded,
    setPostAdded,
    refreshBabyContext,
    updateDevice,
    device,
    refreshGetDefaultBaby,
  };

  return (
    <BabyContext.Provider value={contextValue}>{children}</BabyContext.Provider>
  );
};

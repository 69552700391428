import React from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/style";
import { SmallText, SmallestText } from "../../../shared/SharedStyles";
import {
  getResizedImageUrl,
  formatTimestampToDefaultDate,
} from "../../../../common/helpers";
import { imageSizes } from "../../../../common/constants";
import { AuthContext } from "../../../../context/AuthContext";

interface WeAreInMotionCardProps {
  defaultImage: string;
  databaseImage: string;
  type: string;
  text: string;
  date: number;
  onImageChange: (
    event: React.ChangeEvent<HTMLInputElement>,
    type: string,
    databaseImage: string
  ) => void; // Update the function signature
}

export const WeAreInMotionCard = ({
  defaultImage,
  databaseImage,
  type,
  text,
  date,
  onImageChange,
}: WeAreInMotionCardProps) => {
  const { hasActivePayment } = React.useContext(AuthContext);
  const handleImageClick = () => {
    const input = document.getElementById(type);
    if (input) {
      input.click();
    }
  };

  return (
    <CardContainer $hasImage={databaseImage ? true : false}>
      <ImageInputContainer>
        <CardImage
          $hasImage={databaseImage ? true : false}
          src={
            databaseImage
              ? getResizedImageUrl(databaseImage, imageSizes.medium)
              : defaultImage
          }
          onError={(e) => {
            e.currentTarget.src = databaseImage;
          }}
          onClick={handleImageClick}
        />
        {databaseImage && (
          <DateText>{formatTimestampToDefaultDate(date)}</DateText>
        )}
      </ImageInputContainer>
      <input
        id={type}
        type="file"
        accept="image/*"
        style={{ display: "none" }}
        onChange={(event) => onImageChange(event, type, databaseImage)}
        disabled={!hasActivePayment}
      />
      <SmallText>{text}</SmallText>
    </CardContainer>
  );
};

interface stylesProps {
  $hasImage?: boolean;
}

const CardContainer = styled.div<stylesProps>`
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid ${colors.borderWhite};
  justify-content: space-between;
  align-items: center;
  min-height: 210px;
  max-height: 210px;
  background-color: ${(props) =>
    props.$hasImage ? "#f6ede5" : colors.bgWhite};
  padding: ${(props) => (props.$hasImage ? "0 0 30px 0" : "30px 0")};
`;

const CardImage = styled.img<stylesProps>`
  position: ${(props) => (props.$hasImage ? "relative" : "")};
  flex-grow: 1;
  border-radius: ${(props) => (props.$hasImage ? "16px" : "")};
  width: ${(props) => (props.$hasImage ? "100%" : "70%")};
  padding: ${(props) => (props.$hasImage ? "6px" : "0")};
  height: ${(props) => (props.$hasImage ? "130px" : "100%")};
  max-height: ${(props) => (props.$hasImage ? "130px" : "100px")};
`;

const ImageInputContainer = styled.label`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DateText = styled.p`
  position: absolute;
  bottom: -5%;
  background-color: ${colors.darkBrown};
  border-radius: 8px;
  padding: 3px 6px;
  color: ${colors.white};
  ${SmallestText};
`;

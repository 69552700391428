import React from "react";
import styled from "styled-components";
import { BabyImage } from "../../../feed/common/shared/BabyImage";
import editIcon from "../../../../images/editIcon.svg";
import { colors } from "../../../../styles/style";
import { useBabyContext } from "../../../../context/BabyContext";
import { useNavigate } from "react-router-dom";
import {
  calculateAgeFromTimestamp,
  capitalizeFirstLetter,
  getResizedImageUrl,
} from "../../../../common/helpers";
import {
  FlexRowBetweenCenter,
  SmallIcon,
  SmallText,
  SmallestText,
} from "../../../shared/SharedStyles";
import { imageSizes } from "../../../../common/constants";

interface BabyBadgeProps {
  baby: {
    name: string;
    gender: string;
    age?: number;
    image: string;
    id: string;
  };
}

export const BabyBadge: React.FC<BabyBadgeProps> = ({ baby }) => {
  const { name, gender, age, image, id } = baby;

  const navigate = useNavigate();
  const { updateSelectedBaby } = useBabyContext();

  const handleOnClick = async () => {
    await updateSelectedBaby(id);
    navigate("/baby-info");
  };

  return (
    <Container onClick={handleOnClick}>
      <BabyImage
        alt="Baby Image"
        src={getResizedImageUrl(image, imageSizes.thumbnail)}
        onError={(e) => {
          e.currentTarget.src = image;
        }}
      />
      <DetailsAndIcon>
        <BabyDetails>
          <SmallText $color={colors.darkBrown}>{name}</SmallText>
          {age ? (
            <BabyAgeAndGender>{`${capitalizeFirstLetter(
              gender
            )}, ${calculateAgeFromTimestamp(age)}`}</BabyAgeAndGender>
          ) : (
            <BabyAgeAndGender>{`${capitalizeFirstLetter(
              gender
            )}`}</BabyAgeAndGender>
          )}
        </BabyDetails>
        <SmallIcon src={editIcon} alt="Edit Icon" />
      </DetailsAndIcon>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border-radius: 32px;
  background: ${colors.softGrey};
  padding: 8px 12px;
  gap: 12px;
`;

const DetailsAndIcon = styled.div`
  flex-grow: 1;
  ${FlexRowBetweenCenter}
`;

const BabyDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

const BabyAgeAndGender = styled.p`
  color: ${colors.softBrown};
  ${SmallestText};
  letter-spacing: -0.21px;
`;

import React, { useState, useRef, ReactNode } from "react";
import styled from "styled-components";
import addImageIcon from "../../images/addImageIcon.svg";
import { colors } from "../../styles/style";
import show_icon from "../../images/Show.svg";
import hide_icon from "../../images/Hide.svg";
import remove_image_icon from "../../images/remove-image-icon.svg";
import { SmallIcon, SmallTextConstant } from "../../pages/shared/SharedStyles";

interface CustomTextAreaProps {
  label: string;
  placeholder: string;
  name?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  error?: string;
  maxLength?: number;
}

const TextAreaField = styled.textarea`
  width: 100%;
  height: 120px;
  padding: 12px 16px;
  border-radius: 16px;
  border: 1px solid #d9dcdf;
  background: ${colors.white};
  resize: none;
  outline: none;
  font-family: "Circular Std";
  ${SmallTextConstant}
`;

export const CustomTextArea: React.FC<CustomTextAreaProps> = ({
  label,
  placeholder,
  name,
  value,
  onChange,
  error,
  maxLength,
}) => {
  return (
    <InputWrapper>
      <Label>{label}</Label>
      <InputContainer>
        <TextAreaField
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
          maxLength={maxLength}
        />
      </InputContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputWrapper>
  );
};

interface CustomInputProps {
  label: string;
  placeholder: string;
  icon?: React.ReactNode;
  name?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  $bigLabel?: boolean;
}

const InputWrapper = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

const InputContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
`;

interface LabelProps {
  $bigLabel?: boolean;
}

const Label = styled.label<LabelProps>`
  color: ${colors.darkBrown};
  font-size: 14px;
  font-size: ${({ $bigLabel }) => ($bigLabel ? "16px" : "14px")};
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.21px;
  display: block;
  margin-bottom: 12px;
`;

const InputField = styled.input`
  width: 100%;
  height: 48px;
  padding: 0 16px;
  border-radius: 16px;
  border: 1px solid #d9dcdf;
  background: ${colors.white};
  outline: none;
`;

const DateInputField = styled.input`
  width: 100%;
  flex-grow: 1;
  height: 48px;
  padding: 0 16px;
  margin: 0;
  border-radius: 16px;
  border: 1px solid #d9dcdf;
  background: ${colors.white};
  outline: none;
  box-sizing: border-box;
`;

const IconContainer = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
  font-family: "Nunito Sans";
`;

const ErrorMessage = styled.div`
  color: ${colors.red};
  font-family: "Nunito Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  margin: 2px 0 0 0;
`;

export const CustomTextInput: React.FC<CustomInputProps> = ({
  label,
  placeholder,
  icon,
  name,
  value,
  onChange,
  error,
  $bigLabel,
}) => {
  return (
    <InputWrapper>
      <Label $bigLabel={$bigLabel}>{label}</Label>
      <InputContainer>
        <InputField
          type="text"
          placeholder={placeholder}
          name={name}
          value={value}
          onChange={onChange}
        />
        <IconContainer>{icon}</IconContainer>
      </InputContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputWrapper>
  );
};

export const CustomDateInput: React.FC<CustomInputProps> = ({
  label,
  placeholder,
  icon,
  name,
  value,
  onChange,
  error,
  $bigLabel = false,
}) => {
  return (
    <InputWrapper>
      <Label $bigLabel={$bigLabel}>{label}</Label>
      <InputContainer>
        <DateInputField
          type="date"
          name={name}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
        />
        <IconContainer>{icon}</IconContainer>
      </InputContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputWrapper>
  );
};

export const CustomPasswordInput: React.FC<CustomInputProps> = ({
  label,
  placeholder,
  onChange,
  error,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <InputWrapper>
      <Label>{label}</Label>
      <InputContainer>
        <InputField
          type={showPassword ? "text" : "password"}
          placeholder={placeholder}
          onChange={onChange}
        />
        <IconContainer>
          {showPassword ? (
            <img
              src={hide_icon}
              alt="Eye Icon"
              onClick={() => setShowPassword(!showPassword)}
            />
          ) : (
            <img
              src={show_icon}
              alt="Eye Icon"
              onClick={() => setShowPassword(!showPassword)}
            />
          )}
        </IconContainer>
      </InputContainer>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </InputWrapper>
  );
};

interface CustomCheckboxProps {
  checked: boolean;
  onChange: (checked: boolean) => void;
  error: string;
  children: ReactNode;
}

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const CheckboxInput = styled.input`
  display: none;

  &:checked + label .checkbox {
    &:after {
      transform: translate(-50%, -50%) scale(1);
    }
  }
`;

const CheckboxLabel = styled.label`
  position: relative;
  font-size: 14px;
  cursor: pointer; /* Add this line to make the label clickable */
`;

const Checkbox = styled.span`
  border: 1px solid ${colors.deepBrown};
  border-radius: 4px;
  width: 16px;
  height: 16px;
  display: inline-block;
  position: relative;
  margin-right: 16px;

  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 10px;
    height: 10px;
    background-color: ${colors.deepBrown};
    border-radius: 3px;
    transition: 0.3s;
  }
`;

export const CustomCheckbox: React.FC<CustomCheckboxProps> = ({
  checked,
  onChange,
  error,
  children,
}) => {
  return (
    <React.Fragment>
      <CheckboxWrapper>
        <CheckboxInput
          type="checkbox"
          id="termsCheckbox" // Add this line to include an id attribute
          checked={checked}
          onChange={(e) => onChange(e.target.checked)}
        />
        <CheckboxLabel htmlFor="termsCheckbox">
          {" "}
          {/* Add the htmlFor attribute */}
          <Checkbox className="checkbox"></Checkbox>
        </CheckboxLabel>
        {children}
      </CheckboxWrapper>
      {error && <ErrorMessage>{error}</ErrorMessage>}
    </React.Fragment>
  );
};

interface ImageUploadContainerProps {
  $justifyContent?: string;
}

const ImageUploadContainer = styled.label<ImageUploadContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ $justifyContent }) => $justifyContent || "flex-start"};
  border-radius: 16px;
  border: 1px solid #d9dcdf;
  min-height: 94px;
  height: fit-content;
  background-color: ${colors.white};
  margin-bottom: 17px;
`;

const SelectedImage = styled.img`
  width: 144px;
  height: 78px;
  flex-shrink: 0;
  border-radius: 12px;
  margin: 0 8px;
`;

interface CustomBabyProfileImageInputProps {
  label: string;
  handleChangeImage: (file: any) => void;
}

export const CustomBabyProfileImageInput: React.FC<
  CustomBabyProfileImageInputProps
> = ({ label, handleChangeImage }) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];

    if (file) {
      setSelectedImage(URL.createObjectURL(file));
    }
    handleChangeImage(file);
  };

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <React.Fragment>
      <Label>{label}</Label>
      <ImageUploadContainer
        onClick={() => handleClick}
        $justifyContent={selectedImage ? "" : "center"}
      >
        {selectedImage ? (
          <>
            <SelectedImage src={selectedImage} alt="Selected" />
            <SmallIcon
              src={remove_image_icon}
              alt="Remove"
              onClick={() => {
                setSelectedImage(null);
                handleChangeImage(null);
              }}
            />
          </>
        ) : (
          <>
            <SmallIcon
              src={addImageIcon}
              alt="Custom Icon"
              onClick={() => handleClick}
            />
          </>
        )}

        <input
          type="file"
          ref={fileInputRef}
          style={{ display: "none" }}
          accept="image/*"
          onChange={handleImageChange}
        />
      </ImageUploadContainer>
    </React.Fragment>
  );
};

interface SelectGenderOptionsProps {
  options: { value: string; image: string; text: string }[];
  onSelect: (selectedOption: string) => void;
  label: string;
  value: string;
}

const SelectorWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const SelectorOption = styled.div<{ $isSelected: boolean }>`
  width: 140px;
  height: 140px;
  border: ${({ $isSelected }) =>
    $isSelected ? `2px solid ${colors.darkBrown}` : "2px solid #D9DCDF"};
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${colors.white};
`;

const OptionImage = styled.img`
  width: 60px; /* Adjust width as needed */
  height: 60px; /* Adjust height as needed */
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 8px; /* Adjust margin as needed */
`;

export const SelectGenderOptions: React.FC<SelectGenderOptionsProps> = ({
  options,
  onSelect,
  label,
  value,
}) => {
  const [selectedOption, setSelectedOption] = useState<string | null>(value);

  const handleOptionClick = (option: string) => {
    setSelectedOption(option);
    onSelect(option);
  };

  return (
    <React.Fragment>
      <Label>{label}</Label>
      <SelectorWrapper>
        {options.map((option) => (
          <SelectorOption
            key={option.value}
            $isSelected={selectedOption === option.value}
            onClick={() => handleOptionClick(option.value)}
          >
            <OptionImage src={option.image} alt={`Image for ${option.value}`} />
            <span>{option.text}</span>
          </SelectorOption>
        ))}
      </SelectorWrapper>
    </React.Fragment>
  );
};

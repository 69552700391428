import styled from "styled-components";
import { colors } from "../../../../styles/style";

const HeaderImage = styled.img`
  width: 48px;
  height: 48px;
  flex-shrink: 0;
  border-radius: 48px;
  border: 4px solid ${colors.white};
  border-radius: 50%;
  box-shadow: 0px 2px 21px 5px ${colors.darkGrey};

  align-self: center;
`;
interface BabyImageProps {
  src: string;
  alt: string;
  onClick?: () => void;
  onError?: (e: React.SyntheticEvent<HTMLImageElement, Event>) => void;
}

export const BabyImage: React.FC<BabyImageProps> = ({
  src,
  alt,
  onClick,
  onError,
}) => {
  return (
    <HeaderImage src={src} alt={alt} onClick={onClick} onError={onError} />
  );
};

import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/style";
import { EditModal } from "../../../shared/EditModal";
import { CustomTextArea } from "../../../../helpers/components/CustomInputs";
import Button from "../../../../helpers/components/Button";
import {
  FlexRowBetweenCenter,
  MediumIcon,
  SmallText,
} from "../../../shared/SharedStyles";
import goBackIcon from "../../../../images/goBackIcon.svg";
import { SharedButton } from "../../../settings/common/shared/SharedButton";
import penIcon from "../../../../images/penIconBlack.svg";

interface AddTextModalProps {
  isOpen: boolean;
  initialText: string;
  onClose: () => void;
  onSubmit: (text: string) => void | Promise<void>;
}

export const AddTextModal: React.FC<AddTextModalProps> = ({
  isOpen,
  initialText,
  onClose,
  onSubmit,
}) => {
  const [text, setText] = useState(
    initialText !== undefined ? initialText : ""
  );

  const maxCharacters = 40;
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const newText = event.target.value;
    if (newText.length <= maxCharacters) {
      setText(newText);
    }
  };

  const onSubmitClick = () => {
    onClose();
    onSubmit(text);
  };
  return (
    <EditModal isOpen={isOpen} onClose={onClose}>
      <HeaderContainer>
        <MediumIcon src={goBackIcon} onClick={onClose} />
        <SmallText color={colors.darkBrown} style={{ margin: "auto" }}>
          Add Text
        </SmallText>
        <CloseButton onClick={() => onClose()}>
          <CloseIcon className="bi bi-x-lg" />
        </CloseButton>
      </HeaderContainer>
      <AddTextForm>
        <CustomTextArea
          label=""
          onChange={handleChange}
          value={text}
          name="height"
          placeholder="Enter your text here"
          maxLength={maxCharacters}
        />
        <CharacterCounter>
          {text.length}/{maxCharacters}
        </CharacterCounter>
        <SharedButton
          iconSrc={penIcon}
          buttonText={"Done"}
          onClick={onSubmitClick}
        />
        <Button $button_type="colored" onClick={() => onClose()}>
          Cancel
        </Button>
      </AddTextForm>
    </EditModal>
  );
};

const HeaderContainer = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px;
  width: 100%;
`;

const AddTextForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 0 12px;
`;

const CharacterCounter = styled.div`
  align-self: flex-end;
  color: ${colors.softBrown};
  font-size: 14px;
`;
const CloseButton = styled.button`
  position: absolute;
  top: 9%;
  right: 9%;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
const CloseIcon = styled.i`
  font-size: 20px;
`;

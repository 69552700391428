import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "../../../../styles/style";
import { EditModal } from "../../../shared/EditModal";
import { CustomDateInput } from "../../../../helpers/components/CustomInputs";
import Button from "../../../../helpers/components/Button";
import {
  FlexRowBetweenCenter,
  MediumIcon,
  SmallText,
} from "../../../shared/SharedStyles";
import goBackIcon from "../../../../images/goBackIcon.svg";
import { SharedButton } from "../../../settings/common/shared/SharedButton";
import penIcon from "../../../../images/penIconBlack.svg";
import { addTeethChartData } from "../../../../api";
import { useBabyContext } from "../../../../context/BabyContext";
import { AuthContext } from "../../../../context/AuthContext";

interface AddTeethDateModalProps {
  isOpen: boolean;
  position: number;
  onClose: () => void;
  onRefresh: () => void;
}

export const AddTeethDateModal: React.FC<AddTeethDateModalProps> = ({
  isOpen,
  position,
  onClose,
  onRefresh,
}) => {
  const { hasActivePayment } = React.useContext(AuthContext);
  const today = new Date().toISOString().split("T")[0];
  const [date, setDate] = useState(today);
  const { selectedBabyId } = useBabyContext();
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    try {
      await addTeethChartData({
        baby_id: selectedBabyId,
        date: Math.floor(new Date(date).getTime() / 1000),
        position: position,
      });
      onRefresh();
      onClose();
    } catch (error) {
      console.error("Error adding teeth chart data", error);
    }
    setLoading(false);
  };

  return (
    <EditModal isOpen={isOpen} onClose={onClose}>
      <HeaderContainer>
        <MediumIcon src={goBackIcon} onClick={onClose} />
        <SmallText color={colors.darkBrown} style={{ margin: "auto" }}>
          Select Date
        </SmallText>
        <CloseButton onClick={() => onClose()}>
          <CloseIcon className="bi bi-x-lg" />
        </CloseButton>
      </HeaderContainer>
      <AddTextForm>
        <CustomDateInput
          label=""
          placeholder="Date of Birth"
          value={date}
          onChange={(e) => setDate(e.target.value)}
        />
        <SharedButton
          iconSrc={penIcon}
          buttonText={"Done"}
          onClick={() => handleSubmit()}
          loading={loading}
          $disabled={loading || !hasActivePayment}
        />
        <Button $button_type="colored" onClick={() => onClose()}>
          Cancel
        </Button>
      </AddTextForm>
    </EditModal>
  );
};

const HeaderContainer = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px;
  width: 100%;
`;

const AddTextForm = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 0 12px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 9%;
  right: 9%;
  background-color: transparent;
  border: none;
  cursor: pointer;
`;
const CloseIcon = styled.i`
  font-size: 20px;
`;

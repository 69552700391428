import axios from "axios";

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

axiosClient.interceptors.response.use(
  (response) => response,
  (err) => {
    if (err.response?.status === 401) {
      localStorage.removeItem("little-feather-token");
      window.location.reload();
    }
    if (err.response?.status === 409 || err.response?.status === 404) {
      //toast.error(${err.response.data.status})
    } else {
      //toast.error("An error occurred executing the last request!")
    }
    return Promise.reject(err);
  }
);

const defaultOptions = () => {
  const accessToken = localStorage.getItem("little-feather-token");
  if (accessToken) {
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }
  return {};
};

const client = {
  get: (url: string, options = {}) =>
    axiosClient.get(url, { ...defaultOptions(), ...options }),
  post: (url: string, data: any, options = {}) =>
    axiosClient.post(url, data, { ...defaultOptions(), ...options }),
  put: (url: string, data: any, options = {}) =>
    axiosClient.put(url, data, { ...defaultOptions(), ...options }),
  delete: (url: string, options = {}) =>
    axiosClient.delete(url, { ...defaultOptions(), ...options }),
  patch: (url: string, data: any, options = {}) =>
    axiosClient.patch(url, data, { ...defaultOptions(), ...options }),
};
const registerUserOptions = (accessToken: string) => {
  if (accessToken) {
    return {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
  }
  return {};
};
const registerUserClient = {
  post: (url: string, data: any, options: any = {}) =>
    axiosClient.post(url, data, {
      ...registerUserOptions(data.accessToken ?? ""),
      ...options,
    }),
};

// const options = () => {
//   // eslint-disable-line no-unused-vars, @typescript-eslint/no-unused-vars
//   const accessToken = localStorage.getItem('firebase-token')
//   if (accessToken) {
//     return {
//       headers: {
//         Authorization: `Bearer ${accessToken}`,
//       },
//     }
//   }
//   return {}
// }

//Users
export const addUser = (user: any) => registerUserClient.post("/user", user);
export const authenticateUser = () => client.get("/user/auth");
export const deleteAccount = () => client.delete(`/user`);

//Baby
export const addBaby = (baby: any) => client.post("/baby", baby);
export const getBabyById = (id: any) => client.get(`/baby/${id}`);
export const getAllBabies = () => client.get("/baby");
export const getLatestAddedBaby = () => client.get("/baby/latest");
export const updateBabyData = (id: any, baby: any) =>
  client.patch(`/baby/${id}`, baby);
export const addBabyMeasurement = (id: any, measurements: any) =>
  client.post(`/baby/${id}/Measurement`, measurements);

//Post
export const addPost = (post: any) => client.post("/post", post);
export const getAllPostsByBabyId = (babyId: any) =>
  client.get(`/post/${babyId}`);

//Milestones
export const getFamilyTreeByBaby = (babyId: any) =>
  client.get(`/milestone/${babyId}/family-tree`);
export const addFamilyTreeMember = (familyMember: any) =>
  client.post(`/milestone/family-tree`, familyMember);
export const updateFamilyTreeMember = (babyId: any, familyMember: any) =>
  client.patch(`/milestone/${babyId}/family-tree`, familyMember);
export const getMonthlyDiaryByBaby = (babyId: any, year: any) => {
  return client.get(`/milestone/${babyId}/month-by-month?year=${year}`);
};
export const addMonthlyDiary = (monthlyDiary: any) =>
  client.post(`/milestone/month-by-month`, monthlyDiary);
export const updateMonthlyDiary = (monthlyDiary: any) =>
  client.patch(
    `/milestone/${monthlyDiary.baby_id}/month-by-month`,
    monthlyDiary
  );
export const getFirstYearByBaby = (babyId: any, year: any) =>
  client.get(`/milestone/${babyId}/year-milestone?year=${year}`);
export const addFirstYear = (firstYear: any) =>
  client.post(`/milestone/year-milestone`, firstYear);
export const updateFirstYear = (babyId: any, firstYear: any) =>
  client.patch(`/milestone/${babyId}/year-milestone`, firstYear);

// We are in motion Miletsone
export const getInMotionDataByBaby = (babyId: any) =>
  client.get(`/milestone/${babyId}/in-motion`);
export const addInMotionData = (inMotion: any) =>
  client.post(`/milestone/in-motion`, inMotion);
export const updateInMotionData = (babyId: any, inMotion: any) =>
  client.patch(`/milestone/${babyId}/in-motion`, inMotion);

// My first birthday Milestone
export const getFirstBirthdayData = (babyId: any) =>
  client.get(`/milestone/${babyId}/first-birthday`);
export const addFirstBirthdayData = (firstBirthday: any) =>
  client.post(`/milestone/first-birthday`, firstBirthday);
export const updateFirstBirthdayData = (babyId: any, firstBirthday: any) =>
  client.patch(`/milestone/${babyId}/first-birthday`, firstBirthday);

// First Holiday Milestone
export const getFirstHolidayData = (babyId: any, holidayType: any) =>
  client.get(`/milestone/${babyId}/${holidayType}/first-holiday`);
export const addFirstHolidayData = (firstBirthday: any) =>
  client.post(`/milestone/first-holiday`, firstBirthday);
export const updateFirstHolidayData = (babyId: any, firstBirthday: any) =>
  client.patch(`/milestone/${babyId}/first-holiday`, firstBirthday);

// Teeth Chart Milestone
export const getTeethChartData = (babyId: any) =>
  client.get(`/milestone/${babyId}/teeth-chart`);
export const addTeethChartData = (teethChart: any) =>
  client.post(`/milestone/teeth-chart`, teethChart);
// export const updateTeethChartData = (babyId: any, teethChart: any) =>
//   client.patch(`/milestone/${babyId}/teeth-chart`, teethChart);

//Subscriptions
export const createSubscriptionSession = (priceId: any) =>
  client.post(`/payment`, priceId);
export const getUserSubscription = () => client.get(`/payment/subscription`);
export const cancelSubscription = (subscriptionId: string) =>
  client.post(`/payment/${subscriptionId}/cancel`, {});

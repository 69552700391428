import React, { useState, useEffect } from "react";
import styled from "styled-components";
import goBackIcon from "../../../images/goBackIcon.svg";
import Button from "../../../helpers/components/Button";
import {
  BigHeaderText,
  FlexRowBetweenCenter,
  MediumIcon,
} from "../../shared/SharedStyles";
import { useNavigate } from "react-router-dom";
import { WeAreInMotionCard } from "./common/WeAreInMotionCard";
import { uploadImageToStorage } from "../../../common/helpers";
import { useBabyContext } from "../../../context/BabyContext";
import {
  addInMotionData,
  getInMotionDataByBaby,
  updateInMotionData,
} from "../../../api";
import LoadingSpinner from "../../../common/LoadingSpinner";
import { weAreInMotionNodes } from "../../../common/constants";
import {
  MilestoneButtonWrapper,
  MilestoneContainer,
  MilestoneWrapper,
} from "../common/SharedComponents";

export const WeAreInMotion = () => {
  const navigate = useNavigate();
  const goBackHandler = () => {
    navigate(-1);
  };
  const { selectedBabyId } = useBabyContext();
  const [loading, setLoading] = useState(false);

  const [inMotionNodes, setInMotionNodes] = useState<any>(weAreInMotionNodes);

  const handleImageChange = async (
    event: any,
    type: string,
    databaseImage: string
  ) => {
    setLoading(true);
    const file = event.target.files[0];

    const uploadedImage = await uploadImageToStorage(
      file,
      "images/milestones/in-motion/"
    );
    if (!databaseImage) {
      await addInMotionData({
        baby_id: selectedBabyId,
        image: uploadedImage.imageUrl,
        type: type,
      });
    } else {
      await updateInMotionData(selectedBabyId, {
        baby_id: selectedBabyId,
        image: uploadedImage.imageUrl,
        type: type,
      });
    }

    fetchInMotionData();
    setLoading(false);
  };

  const fetchInMotionData = async () => {
    setLoading(true);
    await getInMotionDataByBaby(selectedBabyId)
      .then(({ data }) => {
        setInMotionNodes((prev: any) => {
          return prev.map((member: any) => {
            const memberData = data.find((d: any) => d.type === member.type);
            if (memberData) {
              return {
                ...member,
                databaseImage: memberData.image,
                date: memberData.created_at,
              };
            }
            return member;
          });
        });
      })
      .catch((err) => {});
    setLoading(false);
  };

  useEffect(() => {
    fetchInMotionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <MilestoneContainer>
        <HeaderContainer>
          <MediumIcon src={goBackIcon} onClick={goBackHandler} />
          <BigHeaderText>We are in motion</BigHeaderText>
          <div></div>
        </HeaderContainer>
        <MilestoneWrapper>
          {loading ? (
            <LoadingSpinner />
          ) : (
            <MotionWrapper>
              {inMotionNodes.map((node: any, index: any) => (
                <WeAreInMotionCard
                  key={index}
                  defaultImage={node.defaultImage}
                  databaseImage={node.databaseImage}
                  type={node.type}
                  text={node.text}
                  date={node.date}
                  onImageChange={handleImageChange}
                />
              ))}
            </MotionWrapper>
          )}
        </MilestoneWrapper>
        <MilestoneButtonWrapper>
          <Button $button_type="colored" onClick={goBackHandler}>
            Go Back
          </Button>
        </MilestoneButtonWrapper>
      </MilestoneContainer>
    </React.Fragment>
  );
};

const HeaderContainer = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px;
  width: 100%;
`;

const MotionWrapper = styled.div`
  align-items: center;
  display: grid;
  margin: 20px 20px;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
`;

import React from "react";
import { NavigationMenu } from "../shared/NavigationMenu";
import { ProfileCard } from "./common/ProfileCard";
import { ProfileHeader } from "./common/ProfileHeader";
import { ProfilePicture } from "./common/ProfilePicture";
import { ProfileName } from "./common/ProfileName";
import { HeightAndWeight } from "./common/HeightAndWeight";
import { ProfileTimeline } from "./common/ProfileTimeline";
import { useBabyContext } from "../../context/BabyContext";

export const ProfilePage = () => {
  const { selectedBaby } = useBabyContext();
  return (
    <React.Fragment>
      <ProfileCard>
        <ProfileHeader />
        {!selectedBaby ? (
          <NoBabySelectedErrorText />
        ) : (
          <>
            <ProfilePicture />
            <ProfileName />
            <HeightAndWeight />
            <ProfileTimeline />
          </>
        )}
      </ProfileCard>
      <NavigationMenu />
    </React.Fragment>
  );
};

export const NoBabySelectedErrorText = () => {
  const containerStyle = {
    display: "flex",
    alignItems: "center",
    grow: 1,
    justifyContent: "center",
    height: "100%",
    margin: "40px 0 0 0",
  };

  const headingStyle: any = {
    textAlign: "center",
  };
  return (
    <div style={containerStyle}>
      <h1 style={headingStyle}>No baby is selected.</h1>
    </div>
  );
};

import React, { useState } from "react";
import styled from "styled-components";
import Button from "../../helpers/components/Button";
import bgImageFlower from "../../images/bg_image_flower.svg";
import babyBoyIcon from "../../images/babyBoyIcon.svg";
import babyGirlIcon from "../../images/babyGirlIcon.svg";
import birdImage from "../../images/bird.svg";
import {
  CustomDateInput,
  CustomBabyProfileImageInput,
  CustomTextInput,
  SelectGenderOptions,
} from "../../helpers/components/CustomInputs";
import {
  BackgroundImage,
  ButtonWrapper,
} from "../authentication/shared/SharedStyles";
import { OnboardingCard } from "../shared/OnboardingCard";
import { useNavigate } from "react-router";
import { colors } from "../../styles/style";
import { addBaby } from "../../api";
import { useBabyContext } from "../../context/BabyContext";
import { uploadImageToStorage } from "../../common/helpers";
import { BigText } from "../shared/SharedStyles";

export const AddBabyForm = () => {
  const navigate = useNavigate();
  const { updateSelectedBaby } = useBabyContext();

  const [currentPage, setCurrentPage] = useState(1);
  const [babyData, setBabyData] = useState({
    babyName: "",
    dateOfBirth: "",
    weight: "",
    height: "",
    babyPhoto: "",
    selectedGender: "",
    profileImage: null,
  });
  const [loading, setLoading] = useState(false);

  const genderOptions = [
    { value: "male", image: babyBoyIcon, text: "Boy" },
    { value: "female", image: babyGirlIcon, text: "Girl" },
  ];
  const handleProceed = () => {
    setCurrentPage(2);
  };

  const handleGoBack = () => {
    currentPage === 2 ? setCurrentPage(1) : navigate(-1);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);
    try {
      if (babyData.profileImage) {
        await uploadImageToStorage(babyData.profileImage)
          .then((res) => {
            setBabyData({ ...babyData, babyPhoto: res.imageUrl });
            babyData.babyPhoto = res.imageUrl;
          })
          .catch(() => {});
      }
      const formattedBabyData = {
        name: babyData.babyName,
        date_of_birth: new Date(babyData.dateOfBirth).getTime(),
        gender: babyData.selectedGender,
        profile_picture: babyData.babyPhoto, // Replace with the actual URL
        measurements: [
          {
            weight: parseInt(babyData.weight),
            height: parseInt(babyData.height),
          },
        ],
      };
      await addBaby(formattedBabyData)
        .then(async ({ data }) => {
          await updateSelectedBaby(data.baby_id);
          navigate("/baby-info");
        })
        .catch(() => {});
    } catch (error) {}
    setLoading(false);
  };
  const handleImageChange = (file: any) => {
    setBabyData({ ...babyData, profileImage: file });
  };

  return (
    <OnboardingCard $margin="0">
      <BackgroundImage
        $position="fixed"
        $left="50%"
        $bottom="-14%"
        $transform="translate(-50%)"
        $width="390px"
        alt="Frame"
        src={bgImageFlower}
      />

      <MainContainer>
        <AdditionalImage src={birdImage} alt="Additional Image" />
        <ContentContainer>
          <WelcomeText>
            <BigText color={colors.darkBrown}>Add baby</BigText>
          </WelcomeText>
          <FormWrapper>
            {currentPage === 1 && (
              <>
                <CustomTextInput
                  label="Baby Name"
                  placeholder="Enter your baby name"
                  value={babyData.babyName}
                  onChange={(e) =>
                    setBabyData({ ...babyData, babyName: e.target.value })
                  }
                />
                <CustomDateInput
                  label="Date of Birth"
                  placeholder="Date of Birth"
                  value={babyData.dateOfBirth}
                  onChange={(e) =>
                    setBabyData({ ...babyData, dateOfBirth: e.target.value })
                  }
                />
                <LengthAndHeightWrapper>
                  <CustomTextInput
                    label="Weight"
                    placeholder="Enter Weight"
                    value={babyData.weight}
                    onChange={(e) =>
                      setBabyData({ ...babyData, weight: e.target.value })
                    }
                    icon={"gr"}
                  />
                  <CustomTextInput
                    label="Height"
                    placeholder="Enter Height"
                    value={babyData.height}
                    onChange={(e) =>
                      setBabyData({ ...babyData, height: e.target.value })
                    }
                    icon={"cm"}
                  />
                </LengthAndHeightWrapper>
              </>
            )}

            {currentPage === 2 && (
              <>
                <CustomBabyProfileImageInput
                  label="Baby Photo"
                  handleChangeImage={handleImageChange}
                />
                <SelectGenderOptions
                  label="Select Gender"
                  options={genderOptions}
                  value={babyData.selectedGender}
                  onSelect={(value) =>
                    setBabyData({ ...babyData, selectedGender: value })
                  }
                />
              </>
            )}

            {currentPage === 1 ? (
              <ButtonWrapper>
                <Button
                  $button_type="colored"
                  onClick={() => handleProceed()}
                  disabled={
                    babyData.babyName.length === 0 ||
                    babyData.dateOfBirth.length === 0
                  }
                >
                  Proceed
                </Button>
                <Button $button_type="primary" onClick={() => handleGoBack()}>
                  Go Back
                </Button>
              </ButtonWrapper>
            ) : (
              <ButtonWrapper>
                <Button
                  $button_type="colored"
                  onClick={(e) => handleSubmit(e)}
                  disabled={
                    !babyData.selectedGender ||
                    !babyData.profileImage ||
                    loading
                  }
                  loading={loading}
                >
                  Finish
                </Button>
                <Button $button_type="primary" onClick={() => handleGoBack()}>
                  Go Back
                </Button>
              </ButtonWrapper>
            )}
          </FormWrapper>
        </ContentContainer>
      </MainContainer>
    </OnboardingCard>
  );
};

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 100%;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  padding: 0px 40px;
  margin-bottom: 48px;
`;

const AdditionalImage = styled.img`
  width: 100%;
`;

const WelcomeText = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  margin-bottom: 40px;
`;

const LengthAndHeightWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;
const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { colors } from "../../../../styles/style";
import goBackIcon from "../../../../images/goBackIcon.svg";
import Button from "../../../../helpers/components/Button";
import {
  BigHeaderText,
  ExtraSmallIcon,
  FlexRowBetweenCenter,
  MediumIcon,
  ModalOverlay,
  SmallIcon,
  SmallText,
} from "../../../shared/SharedStyles";
import { useNavigate, useParams } from "react-router-dom";
import addIcon from "../../../../images/addIcon.svg";
import { AddTextModal } from "./AddTextModal";
import {
  addFirstHolidayData,
  getFirstHolidayData,
  updateFirstHolidayData,
} from "../../../../api";
import { useBabyContext } from "../../../../context/BabyContext";
import { firstHolidayNode, holidayData } from "../../../../common/constants";
import LoadingSpinner from "../../../../common/LoadingSpinner";
import { uploadImageToStorage } from "../../../../common/helpers";
import { ImageCropper } from "./CropImage";
import {
  MilestoneClickerScriptText,
  MilestoneButtonWrapper,
  MilestoneWrapper,
  MilestoneSelectedImage,
} from "../../common/SharedComponents";
import { AuthContext } from "../../../../context/AuthContext";

export const HolidayDetailsPage = () => {
  const navigate = useNavigate();
  const { hasActivePayment } = React.useContext(AuthContext);
  const goBackHandler = () => {
    navigate(-1);
  };
  const [loading, setLoading] = useState(false);
  const { selectedBabyId } = useBabyContext();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [firstHolidayData, setFirstHolidayData] =
    useState<any>(firstHolidayNode);
  const [showCropperModal, setShowCropperModal] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<any>(null);
  const [selectedPosition, setSelectedPosition] = useState<number | null>(null);

  const { holidayName } = useParams();

  const handleTextSubmit = async (text?: string) => {
    setLoading(true);
    if (firstHolidayData.id) {
      await updateFirstHolidayData(selectedBabyId, {
        baby_id: selectedBabyId,
        images: [...firstHolidayData.images],
        type: holidayName,
        text: text,
      });
    } else {
      await addFirstHolidayData({
        baby_id: selectedBabyId,
        text: text,
        type: holidayName,
        images: [],
      });
    }
    fetchFirstYearData();
    setLoading(false);
  };

  const handleImageUpload = async (file: any, position: number) => {
    setLoading(true);
    // const file = event.target.files[0];
    const uploadedImage = await uploadImageToStorage(
      file,
      "images/milestones/first-holiday/"
    );

    if (firstHolidayData.id) {
      const existingImageIndex = firstHolidayData.images.findIndex(
        (image: any) => image.position === position
      );
      if (existingImageIndex !== -1) {
        // If an image with the same position exists, update its URL
        const updatedImages = [...firstHolidayData.images];
        updatedImages[existingImageIndex].url = uploadedImage.imageUrl;

        await updateFirstHolidayData(selectedBabyId, {
          baby_id: selectedBabyId,
          images: updatedImages,
          text: firstHolidayData.text,
          type: holidayName,
        });
      } else {
        await updateFirstHolidayData(selectedBabyId, {
          baby_id: selectedBabyId,
          images: [
            ...firstHolidayData.images,
            { position: position, url: uploadedImage.imageUrl },
          ],
          text: firstHolidayData.text,
          type: holidayName,
        });
      }
    } else {
      await addFirstHolidayData({
        baby_id: selectedBabyId,
        images: [{ position: position, url: uploadedImage.imageUrl }],
        text: "",
        type: holidayName,
      });
    }
    fetchFirstYearData();
    setSelectedPosition(null);
    setLoading(false);
  };

  const fetchFirstYearData = async () => {
    setLoading(true);
    await getFirstHolidayData(selectedBabyId, holidayName)
      .then(({ data }) => {
        setFirstHolidayData({
          id: data[0].id,
          text: data[0].text,
          images: [...data[0].images],
          type: data[0].type,
        });
      })
      .catch((err) => {});

    setLoading(false);
  };

  useEffect(() => {
    setShowCropperModal(false);
    fetchFirstYearData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const ImageInput = ({ onChange, imageUrl, width, height, disabled }: any) => (
    <ImageInputContainer
      $width={width ? width : "150px"}
      $height={height ? height : "150px"}
      $disabled={disabled}
    >
      <input
        type="file"
        accept="image/*"
        onChange={onChange}
        style={{ display: "none" }}
        disabled={!hasActivePayment}
      />
      {imageUrl ? (
        <MilestoneSelectedImage src={imageUrl} />
      ) : (
        <SmallIcon src={addIcon} />
      )}
    </ImageInputContainer>
  );

  const renderImage = (position: number) => {
    const image = firstHolidayData.images?.find(
      (image: any) => image.position === position
    );
    return image ? image.url : "";
  };

  const handleImageChange = async (event: any, position: number) => {
    const file = event.target.files[0];
    if (!file) return;
    setSelectedPosition(position);
    setShowCropperModal(true);
    setUploadedFile(file);
  };
  const croppedImageActionFinished = async (croppedImage: any) => {
    setShowCropperModal(false);
    if (selectedPosition === null) return;
    await handleImageUpload(croppedImage, selectedPosition as number);
    setUploadedFile(null);
  };
  return (
    <React.Fragment>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Container>
          <HeaderContainer>
            <MediumIcon src={goBackIcon} onClick={goBackHandler} />
            <BigHeaderText>
              My First {holidayData.find((h) => h.value === holidayName)?.name}
            </BigHeaderText>
            <div></div>
          </HeaderContainer>
          <MilestoneWrapper>
            <HolidayWrapper>
              {firstHolidayData.text ? (
                <MilestoneClickerScriptText
                  onClick={() => {
                    if (hasActivePayment) {
                      setIsModalOpen(true);
                    }
                  }}
                >
                  {firstHolidayData.text}
                </MilestoneClickerScriptText>
              ) : (
                <ButtonContainer
                  onClick={() => {
                    if (hasActivePayment) {
                      setIsModalOpen(true);
                    }
                  }}
                >
                  <ImageInputContainer $width="38px" $height="38px">
                    <ExtraSmallIcon src={addIcon} />
                  </ImageInputContainer>
                  <SmallText>Write some text</SmallText>
                </ButtonContainer>
              )}
              <FirstRow>
                <ImageInput
                  onChange={(event: any) => handleImageChange(event, 0)}
                  disabled={renderImage(0) === "" && !hasActivePayment}
                  imageUrl={
                    renderImage(0)
                    //  &&
                    // getResizedImageUrl(renderImage(0), imageSizes.large)
                  }
                  onError={(e: any) => {
                    e.currentTarget.src = renderImage(0);
                  }}
                  width="200px"
                  height="200px"
                />
                {showCropperModal && (
                  <ModalOverlay>
                    <ImageCropper
                      image={uploadedFile}
                      croppedImageAction={croppedImageActionFinished}
                      closeModal={() => {
                        setShowCropperModal(!showCropperModal);
                        setUploadedFile(null);
                      }}
                    />
                  </ModalOverlay>
                )}
              </FirstRow>
              <SecondRow>
                {[1, 2].map((position) => (
                  <ImageInput
                    key={position}
                    onChange={(event: any) =>
                      handleImageChange(event, position)
                    }
                    disabled={renderImage(position) === "" && !hasActivePayment}
                    imageUrl={
                      renderImage(position)
                      // &&
                      // getResizedImageUrl(
                      //   renderImage(position),
                      //   imageSizes.large
                      // )
                    }
                    onError={(e: any) => {
                      e.currentTarget.src = renderImage(position);
                    }}
                  />
                ))}
              </SecondRow>
            </HolidayWrapper>
          </MilestoneWrapper>
          {!showCropperModal && (
            <MilestoneButtonWrapper>
              <Button $button_type="colored" onClick={goBackHandler}>
                Go Back
              </Button>
            </MilestoneButtonWrapper>
          )}
        </Container>
      )}
      {isModalOpen && (
        <AddTextModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onSubmit={handleTextSubmit}
          initialText={firstHolidayData.text}
        />
      )}
    </React.Fragment>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100vh;
  padding-top: 40px;
  background-color: ${colors.whiteBackgroundColor};
  overflow-y: scroll;
`;

const HeaderContainer = styled.div`
  ${FlexRowBetweenCenter}
  padding: 16px;
  width: 100%;
`;

const HolidayWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  margin: 20px 20px;
  gap: 50px;
`;

const ButtonContainer = styled.div`
  width: fit-content;
  border-radius: 16px;
  background-color: ${colors.softGrey};
  padding: 12px 20px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
`;

interface ImageInputContainerProps {
  $width?: string;
  $height?: string;
  $disabled?: boolean;
}
const ImageInputContainer = styled.label<ImageInputContainerProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => props.$width};
  height: ${(props) => props.$height};
  max-width: 100%;
  max-width: 100%;
  border: 2px solid ${colors.white};
  border-radius: 50%;
  background-color: ${colors.lightPeach};
  box-shadow: 0px 1.348px 14.149px 3.369px rgba(153, 158, 216, 0.2);
  ${(props) =>
    props.$disabled &&
    css`
      cursor: not-allowed;
      opacity: 0.4;
    `}
`;

const FirstRow = styled.div`
  background-color: rgba(255, 247, 242, 0.62);
  border-radius: 50%;
  padding: 24px;
`;

const SecondRow = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

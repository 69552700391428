import styled from "styled-components";
import { colors } from "../../styles/style";

interface ColorProps {
  $color?: string;
}
export const ExtraSmallIcon = styled.img`
  width: 12px;
  height: 12px;
`;

export const SmallIcon = styled.img`
  width: 24px;
  height: 24px;
`;

export const SmallText = styled.div<ColorProps>`
  color: ${(props) => props.$color};
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: -0.24px;
`;

export const SmallTextConstant = `font-size: 16px;
font-style: normal;
font-weight: 450;
line-height: normal;
letter-spacing: -0.24px;`;

export const SmallTextSlim = styled.div<ColorProps>`
  color: ${(props) => props.$color};
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.24px;
`;

export const SmallestText = ` font-size: 14px;
font-style: normal;
font-weight: 450;
line-height: normal;
letter-spacing: -0.21px;`;

export const SmallTextMedium = `
text-align: center;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 24px;
letter-spacing: -0.24px;
`;

interface BigTextProps {
  $color?: string;
  $padding_bottom?: string;
}

export const BigText = styled.h2<BigTextProps>`
  color: ${(props) => props.$color};
  padding-bottom: ${(props) => props.$padding_bottom};
  font-size: 30px;
  font-style: normal;
  font-weight: 450;
  line-height: 62px;
  letter-spacing: -0.45px;
`;

interface MediumTextProps {
  $color?: string;
  $margin_top?: string;
}

export const MediumText = styled.div<MediumTextProps>`
  font-size: 27px;
  font-weight: 400;
  left: 0;
  letter-spacing: -0.4px;
  line-height: 62px;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: ${(props) => props.$color};
  margin-top: ${(props) => props.$margin_top};
`;

export const HeaderText = styled.h2<ColorProps>`
  color: ${(props) => props.$color || colors.darkBrown};
  font-size: 20px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
  letter-spacing: -0.3px;
`;

export const BigHeaderText = styled.h2<ColorProps>`
  color: ${(props) => props.$color || colors.darkBrown};
  font-size: 24px;
  font-style: normal;
  font-weight: 450;
  line-height: normal;
`;

interface OptionProps {
  $active: string;
}

export const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 1px solid ${colors.borderWhite};
  border-radius: 50px;
  gap: 8px;
  padding: 6px;
  margin: 22px 24px 22px 24px;
`;

export const Option = styled.span<OptionProps>`
  flex-grow: 1;
  text-align: center;
  border-radius: 50px;
  background-color: ${({ $active }) =>
    $active === "true" ? colors.warmthBrown : ""};
  color: ${({ $active }) =>
    $active === "true" ? colors.white : colors.warmthBrown};
  margin: 0 5px;
  padding: 10px;
`;

export const FlexRowBetweenCenter = `display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;`;

export const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${colors.softPink};
  display: flex;
  flex-direction: column; /* Adjusted to column layout */
  justify-content: flex-end; /* Align at the bottom */
  align-items: center;
  z-index: 1000;
`;

export const MediumIcon = styled.img`
  width: 48px;
  height: 20px;
`;
